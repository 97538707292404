@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 12, 12, 12;
  --background-rgb: 255, 255, 255;
}


body {
  color: rgb(var(--foreground-rgb));
  background:  rgb(var(--background-rgb))
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}
